/* 
.main {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .advo-content {
    text-align: center;
  }
  
  .zapier-embed-container {
    position: relative;
    width: 100%;
    padding-bottom: 75%; 
    overflow: hidden;
  }
  
  zapier-interfaces-page-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
   */
   @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

   body {
     font-family: 'Poppins';
     height: 100vh;
    overflow: hidden;
    /* background: rgb(205,196,253);
    background: linear-gradient(177deg, rgba(205,196,253,1) 0%, rgba(0,0,0,1) 100%);background: rgb(205,196,253);
    background: linear-gradient(177deg, rgba(205,196,253,1) 0%, rgba(0,0,0,1) 100%); */
    background-image: url(https://advo-login.pages.dev/img/loginImg.png);
    filter: grayscale(0.3);
    background-repeat: no-repeat;
    background-size:250px;
    background-position: center;
 }
 /* .glass{
    background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
 } */
 .main {
    right: 0;
    margin-right: 0%;
    overflow: hidden;
  }
  .heading{
    border-bottom: 2px solid black;
  }
  .heading h1{
    padding: 0;
    margin: 0;
    margin-bottom: 5;
  }
  .heading p{
    padding: 0;
    margin: 0;
  }
  .advo {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .advo h1 {
    font-size: 30px;
  }
  
  .advo h2 {
    font-size: 15px;
    font-weight: light;
  }
  
  .advo-content {
    flex-grow: 1;
  }
  img{
    width: 60px;
    height:60px;
  }
  .zapier-embed-container {
    position: relative;
    width: 100%;
    height: 100%; /* Make the container take the full height of its parent */
  }
  
  zapier-interfaces-page-embed {
    width: 100%;
    height: 100%;
  }
